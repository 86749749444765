import { useEffect, useState } from 'react';
import { SiteUser } from '..';
import { useField } from 'formik';

type UsersListProps = {
  users: SiteUser[];
  criteria: string;
};

export const UsersList = ({ users, criteria }: UsersListProps) => {
  const [{ value: selectedUsers }, , usersHelpers] = useField('users');
  const [{ value: mainContact }, , mainContactHelpers] =
    useField('mainContact');

  const makeMainContact = (id: number) => {
    if (!selectedUsers.includes(id))
      usersHelpers.setValue([...selectedUsers, id]);

    mainContactHelpers.setValue(id);
  };

  const handleCheckboxChange = (userId: number, isChecked: boolean) => {
    if (isChecked) usersHelpers.setValue([...selectedUsers, userId]);
    else {
      usersHelpers.setValue(
        selectedUsers.filter((id: number) => id !== userId)
      );
      if (userId == mainContact) {
        mainContactHelpers.setValue(undefined);
      }
    }
  };

  const filteredUsers = users.filter((user) =>
    user.fullname.includes(criteria)
  );
  return (
    <div className="border-2 z-30 py-2 px-3 max-h-28 overflow-y-scroll">
      {filteredUsers.length > 0
        ? filteredUsers.map((user, index) => (
            <div key={index} className="flex">
              <div className="grow flex items-center gap-x-2">
                <input
                  type="checkbox"
                  checked={selectedUsers.includes(user.id)}
                  onChange={(e) =>
                    handleCheckboxChange(user.id, e.target.checked)
                  }
                />
                <div className="flex gap-x-1">
                  <label>{user.fullname}</label>
                  {user.id === mainContact && (
                    <span className="text-xs text-blue-300-electripure">
                      Main Contact
                    </span>
                  )}
                </div>
              </div>
              {user.id !== mainContact && (
                <span
                  className="text-xs text-blue-100-electripure cursor-pointer"
                  onClick={() => makeMainContact(user.id)}>
                  Make Main Contact
                </span>
              )}
            </div>
          ))
        : 'No users found'}
    </div>
  );
};

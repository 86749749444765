import { IconSearch } from '@/assets/svg';
import { useState } from 'react';

type Props = {
  onFocus: (value: boolean) => void;
  onChange: (value: string) => void;
};

export const SearchBar = ({ onFocus, onChange }: Props) => {
  return (
    <div className="relative w-full">
      <div className="absolute left-2 top-1/2 -translate-y-1/2">
        <IconSearch />
      </div>
      <input
        onFocus={() => onFocus(true)}
        onChange={(e) => onChange(e.target.value)}
        className="border-2 border-gray-200-electripure rounded-sm px-10 py-2 w-full outline-none"
        type="text"
        placeholder="Search users"
      />
    </div>
  );
};

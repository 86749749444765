import {
  ChangeEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { ChartData, ChartDataset } from 'chart.js';
import { BeatLoader } from 'react-spinners';
import { CabinetInformation } from '@/components/CabinetInformation';
import { MDPInformation } from '@/components/MDPInformation';
import { MeterInformation } from '@/components/MeterInformation';
import { MdpOverview } from '@/components/MdpOverview';
import { Dropdown } from '@/common/Form/Dropdown';
import ElectripureService from '@/service/electripure-service';
import {
  CustomChartData,
  CustomDates,
  MDPOverviewRef,
  MeterStatus,
  OverviewPlotData
} from '@/types';
import { TIME_SCALE } from '@/config/enum';
import { getUnixFromTimeScale, options } from '@/utils';
import { LineChart } from '@/common/Chart/LineChart';
import { ChartLegend } from '@/common/Chart/ChartLegend';
import { Line } from 'react-chartjs-2';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Modal } from '@mui/material';
import { DateTime } from '@/common/DateTime';
import { IconCross } from '@/assets/svg';

const MeterStatusPage = () => {
  const { meterId } = useParams();
  const nowRef = useRef<Moment>(moment().utc());
  const now = nowRef.current;
  const [data, setData] = useState<MeterStatus>({} as MeterStatus);
  const [plotData, setPlotData] = useState<{
    loading: boolean;
    data: OverviewPlotData | undefined;
  }>({ loading: false, data: undefined });

  const [selectedTimeScale, setSelectedTimeScale] = useState<TIME_SCALE>(
    TIME_SCALE.LAST_30_DAYS
  );
  const [showCustomInputsModal, setShowCustomInputsModal] =
    useState<boolean>(false);
  const [customDates, setCustomDates] = useState<CustomDates>({
    from: now.clone().subtract(1, 'day'),
    to: now
  });
  const [showButton, setShowButton] = useState<boolean>(false);

  const mdpOverviewRef = useRef<MDPOverviewRef>(null);

  const fetchMdpOverview = useMemo(
    () => mdpOverviewRef.current?.fetchData,
    [mdpOverviewRef.current]
  );

  const fetchData = async (customFrom?: Moment, customTo?: Moment) => {
    if (meterId) {
      if (customFrom && customTo) {
        await Promise.all([
          doFetch(meterId, customFrom, customTo),
          fetchMdpOverview
            ? fetchMdpOverview(customFrom, customTo)
            : Promise.resolve()
        ]);
      } else {
        const now = moment().utc();
        const from = getUnixFromTimeScale(now.clone(), selectedTimeScale);
        await Promise.all([
          doFetch(meterId, from, now),
          fetchMdpOverview ? fetchMdpOverview(from, now) : Promise.resolve()
        ]);
      }
    }
  };

  const doFetch = async (meterId: string, from: Moment, to: Moment) => {
    setPlotData((prev) => ({ ...prev, loading: true }));
    const { data } = await ElectripureService.getOverviewChartMdp(meterId, {
      to: to.format('YYYY-MM-DD'),
      from: from.format('YYYY-MM-DD')
    });
    setPlotData({ loading: false, data });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (meterId) {
        const { data } = await ElectripureService.getMdpSystemInfo(meterId);
        setData(data);
      }
    };

    fetchData();
  }, [meterId]);

  useEffect(() => {
    if (selectedTimeScale !== TIME_SCALE.CUSTOM) {
      fetchData();
    }
  }, [selectedTimeScale, meterId]);

  useEffect(
    () =>
      selectedTimeScale === TIME_SCALE.CUSTOM
        ? setShowButton(true)
        : setShowButton(false),
    [selectedTimeScale]
  );

  const imbalancesPlotData: CustomChartData<string, number> = useMemo(() => {
    if (!plotData.data)
      return {
        labels: [],
        datasets: []
      };

    return {
      labels: plotData.data.curr_volt.datetimes,
      datasets: [
        {
          data: plotData.data.curr_volt.max_imbalances_curr,
          label: 'Current Imbalance',
          borderColor: '#e69138',
          point: {
            pointRadius: 0
          },
          borderWidth: 1
        },
        {
          data: plotData.data.curr_volt.max_imbalances_volt,
          label: 'Voltage Imbalance',
          borderColor: 'blue',
          point: {
            pointRadius: 0
          },
          borderWidth: 1
        }
      ]
    };
  }, [plotData]);

  const kWhPlotData: CustomChartData<string, number> = useMemo(() => {
    if (!plotData.data)
      return {
        labels: [],
        datasets: []
      };

    return {
      labels: plotData.data.kwh.dates,
      datasets: [
        {
          data: plotData.data.kwh.cumulative_values,
          pointRadius: 1,
          borderColor: 'red',
          borderWidth: 1,
          yAxisID: 'y1',
          position: 'right',
          grid: {
            drawOnChartArea: false
          },
          title: {
            display: true,
            text: 'Cumulative kWh',
            color: 'red'
          },
          ticks: {
            color: 'red',
            maxTicksLimit: 5
          },
          point: {
            pointRadius: 0
          }
        },
        {
          data: plotData.data.kwh.daily_values,
          borderColor: 'blue',
          borderWidth: 1,
          yAxisID: 'y2',
          position: 'left',
          point: {
            pointRadius: 3,
            fill: false,
            pointStyle: 'circle'
          },
          title: {
            display: true,
            text: 'kWh, Daily Usage',
            color: 'blue'
          },
          ticks: {
            color: 'blue'
          }
        }
      ]
    };
  }, [plotData]);

  const handleTimescaleChange = ({
    target
  }: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTimeScale(target.value as TIME_SCALE);
  };

  const handleDateTimeChange = (name: string, value: Moment) =>
    setCustomDates((prev) => ({ ...prev, [name]: value }));

  const handleClickButton = () => setShowCustomInputsModal(true);

  const handleClickModal = async () => {
    setShowCustomInputsModal(false);
    await fetchData(customDates.from, customDates.to);
  };

  // console.log({ mdpOverviewRef });
  return (
    <>
      <div className="flex flex-col gap-y-8">
        <div className="flex gap-x-8">
          <div className="grow">
            <MeterInformation status={data.status} meterId={data.meter_id} />
          </div>
          {/* <CabinetInformation /> */}
          <div className="flex grow">
            <MDPInformation name={data.name} />
          </div>
        </div>

        <div className="bg-white p-4 rounded-md border-2 border-gray-200-electripure">
          <div className="flex mb-4 gap-x-4">
            <Dropdown<TIME_SCALE>
              className="ms-auto"
              options={options}
              onChange={handleTimescaleChange}
              defaultValue={selectedTimeScale}
            />
            {showButton && (
              <button
                onClick={handleClickButton}
                className="bg-blue-100-electripure text-white px-3 rounded-lg outline-none">
                Custom input
              </button>
            )}
          </div>
          <div className="flex gap-x-4">
            <MdpOverview
              selectedTimeScale={selectedTimeScale}
              ref={mdpOverviewRef}
            />
            <div className="flex flex-col grow w-full relative gap-8">
              {plotData.loading ? (
                <BeatLoader className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
              ) : (
                <>
                  <LineChart<string, number>
                    ticks={12}
                    data={kWhPlotData}
                    visiblePoints={1000}
                    multipleYAxis
                    highlightWeekends
                  />
                  <LineChart<string, number>
                    data={imbalancesPlotData}
                    ticks={12}
                    visiblePoints={1000}
                    legend
                    title={{
                      display: true,
                      text: '3 Phase Maximum Imbalance [%]'
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showCustomInputsModal}
        onClose={() => setShowCustomInputsModal(false)}>
        <div className=" outline-none absolute top-1/2 left-1/2 -translate-x-1/2 w-80 -translate-y-1/2 bg-white p-12 flex flex-col gap-y-4">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <div
              className="absolute top-3 right-3 cursor-pointer hover:bg-black hover:bg-opacity-20 rounded-full px-1 py-1"
              onClick={() => setShowCustomInputsModal(false)}>
              <IconCross />
            </div>
            <div>
              <p>From</p>
              <DateTime
                onChange={handleDateTimeChange}
                value={customDates.from}
                name="from"
              />
            </div>
            <div>
              <p>To</p>
              <DateTime
                onChange={handleDateTimeChange}
                value={customDates.to}
                name="to"
              />
            </div>
            <button
              onClick={handleClickModal}
              className="bg-blue-100-electripure text-white py-3 rounded-lg">
              Search
            </button>
          </LocalizationProvider>
        </div>
      </Modal>
    </>
  );
};

export default MeterStatusPage;

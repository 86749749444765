import ElectripureService from '@/service/electripure-service';
import { Form, Formik } from 'formik';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { EntityForm } from '../EntityForm';
import { SearchBar } from './SearchBar';
import { UsersList } from './UsersList';
import { Button } from '@/common/Button';
import { toast } from 'react-toastify';

type AddUserToSiteProps = {
  siteId?: string;
  companyId: string;
  closeModal: () => void;
};

export type SiteUser = {
  email: string;
  fullname: string;
  id: number;
  role_name: string;
  scope: string;
  status: any;
  is_assigned: boolean;
  is_main_contact: boolean;
};

export const AddUsersToSite = ({
  siteId,
  companyId,
  closeModal
}: AddUserToSiteProps) => {
  const [users, setUsers] = useState<SiteUser[]>([]);
  const [isSearchBarFocused, setIsSeachBarFocused] = useState<boolean>(false);
  const [criteria, setCriteria] = useState<string>('');

  const formRef = useRef<HTMLDivElement>(null);

  const handleSubmit = (values: any) => {
    try {
      const response = ElectripureService.assignUsersForSite(siteId!, {
        users: values.users,
        main_contact: values.mainContact
      });

      console.log(response);
      closeModal();
    } catch (error) {
      toast.error('Something happened');
    }
  };
  const handleChangeSearchBarFocus = (value: boolean) =>
    setIsSeachBarFocused(value);
  const handleSearchBarChange = (value: string) => setCriteria(value);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (formRef.current && !formRef.current.contains(event.target as Node)) {
        setIsSeachBarFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await ElectripureService.getUsersForSite(
          siteId!,
          companyId
        );
        setUsers(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className="bg-white z-10 absolute top-1/2 left-1/2 -translate-x-1/2 overflow-y-hidden -translate-y-1/2 py-8 px-12 flex flex-col w-[700px]">
      <EntityForm title="Users with Access to Site">
        <Formik<{ users: number[]; mainContact?: number }>
          initialValues={{
            users: users
              .filter((user) => user.is_assigned)
              .map((user) => user.id),
            mainContact: users.find((user) => user.is_main_contact)?.id
          }}
          onSubmit={(values) => handleSubmit(values)}>
          {({ values }) => (
            <div ref={formRef}>
              <SearchBar
                onFocus={handleChangeSearchBarFocus}
                onChange={handleSearchBarChange}
              />
              {isSearchBarFocused && (
                <UsersList users={users} criteria={criteria} />
              )}
              <div className="flex mt-3">
                <Button
                  className="bg-blue-100-electripure text-white grow py-2"
                  onClick={() => handleSubmit(values)}
                  type="submit">
                  Add
                </Button>
                <Button
                  className="text-blue-100-electripure grow py-2"
                  onClick={() => closeModal()}
                  type="button">
                  Skip
                </Button>
              </div>
            </div>
          )}
        </Formik>
      </EntityForm>
    </div>
  );
};

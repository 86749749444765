import { Form, Formik } from 'formik';
import { EntityForm } from '../EntityForm';
import { CreateDeviceFormData } from '@/utils/types';
import { CreateMeterSchema } from '@/schemas';
import { TextInput } from '@/common/Input/Text';
import { Button } from '@/common/Button';
import { Dropdown } from '@/common/Input/Dropdown';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ElectripureState } from '@/interfaces/reducers';
import { CompanyEntity } from '@/interfaces/entities';
import { ObjectRequestMapper } from '@/mappers/request.mapper';
import ElectripureService from '@/service/electripure-service';
import { toast } from 'react-toastify';

type CreateDeviceFormProps = {
  companyId?: number;
  siteId?: number;
};

export const CreateDeviceForm = ({
  companyId,
  siteId
}: CreateDeviceFormProps) => {
  const [currentSelectedCompany, setCurrentSelectedCompany] = useState('');

  const companies: CompanyEntity[] = JSON.parse(
    useSelector((state: ElectripureState) => state.companies)
  );

  const handleSubmit = async (values: CreateDeviceFormData) => {
    const objectMap = {
      applianceId: 'appliance_id',
      meterId: 'meter_id',
      siteId: 'site_id',
      name: 'name',
      rating: 'mdp_rating',
      switchgear: 'switchgear_rating',
      transformer: 'transformer_rating',
      serialNumber: 'serial_number',
      size: 'size'
    };

    const body = ObjectRequestMapper.map<CreateDeviceFormData>(
      values,
      objectMap
    );

    try {
      const response = await ElectripureService.createDeviceV2(body);

      console.log(response);

      toast('MDP created successfully!', {
        type: 'success',
        position: 'bottom-right'
      });
    } catch (error: any) {
      console.error(error);
      toast(`Could not create MDP: ${error.message}`, {
        type: 'error',
        position: 'bottom-right'
      });
    }
  };

  const companyDropdownOptions = useMemo(
    () =>
      [
        {
          value: '',
          text: (
            <span className="text-gray-200-electripure">Select a company</span>
          )
        }
      ].concat(
        companies.map((company) => ({
          value: company.company_id.toString(),
          text: <span>{company.company_name}</span>
        }))
      ),
    [companies]
  );

  const sitesDropdownOptions = useMemo(
    () =>
      [
        {
          value: '',
          text: <p className="text-gray-200-electripure">Select a site</p>
        }
      ].concat(
        // I'm supposed to use companies.find but the types are so messed up
        currentSelectedCompany
          ? companies
              .filter(
                (company) =>
                  company.company_id.toString() == currentSelectedCompany
              )[0]
              ?.list_sites.map((site) => ({
                value: site.id.toString(),
                text: <span>{site.name}</span>
              }))
          : []
      ),
    [companies, currentSelectedCompany]
  );

  return (
    <div className="bg-white z-30 absolute top-1/2 left-1/2 -translate-x-1/2 overflow-y-scroll max-h-screen -translate-y-1/2 p-8">
      <EntityForm title="Let's get some Meter details">
        <Formik<CreateDeviceFormData>
          initialValues={{
            applianceId: '',
            siteId: siteId?.toString() ?? '',
            meterId: '',
            name: '',
            rating: '',
            serialNumber: '',
            size: '',
            switchgear: '',
            transformer: ''
          }}
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={CreateMeterSchema}>
          {({ isSubmitting }) => (
            <Form className="flex flex-col grow gap-y-3">
              <TextInput
                label="Meter Name"
                name="name"
                placeholder="Meter name"
              />
              {!companyId && !siteId && (
                <div className="flex gap-x-4">
                  <div className="w-1/2">
                    <Dropdown
                      options={companyDropdownOptions}
                      handleChange={(e) => {
                        setCurrentSelectedCompany(e.target.value);
                      }}
                      label="Company"
                      name="companyId"
                    />
                  </div>
                  <Dropdown
                    disabled={currentSelectedCompany.length < 0}
                    options={sitesDropdownOptions}
                    label="Site"
                    name="siteId"
                  />
                </div>
              )}
              <TextInput label="Meter ID" name="meterId" placeholder="123456" />
              <TextInput
                label="Appliance ID"
                name="applianceId"
                placeholder="123456"
              />
              <TextInput
                label="MDP rating in AMPs"
                name="rating"
                placeholder="400"
              />
              <TextInput
                label="Switchgear rating in AMPs"
                name="switchgear"
                placeholder="2,000"
              />
              <TextInput
                label="Transformer rating in KVAR"
                name="transformer"
                placeholder="2,000"
              />
              <div>
                <p className="text-blue-300-electripure font-semibold mb-2">
                  Transformer Details
                </p>
                <div className="flex gap-x-12">
                  <TextInput
                    label="Serial Number"
                    name="serialNumber"
                    placeholder="1234567890"
                  />
                  <TextInput label="Size" name="size" placeholder="1232134" />
                </div>
              </div>
              <Button
                type="submit"
                disabled={isSubmitting}
                className="text-sm mt-4 mx-auto flex jutify-center disabled:bg-blue-50-electripure bg-blue-100-electripure text-white h-12 w-40 rounded-md items-center outline-none">
                <p className="w-full text-center">Add Meter</p>
              </Button>
            </Form>
          )}
        </Formik>
      </EntityForm>
    </div>
  );
};
